<div #targetMetrics class="p-20 metrics-ctn">
  <div class="row justify-space-between">
    <h2 class="font-size-16">Histórico de valores da métrica</h2>
    <button type="button" class="btn-icon" (click)="onClose.emit()">
      <img width="14px" src="/assets/icons/close-dialog.svg" alt="fechar" />
    </button>
  </div>
  <div class="row align-center pt-20">
    <div class="flex-1 font-size-14">
      <h3 class="font-semi-bold font-link">Valor inicial</h3>
      <span
        >{{ metric()?.initialValue }} ({{
          metric()?.initialDate | date : "dd/MM/yyyy" : "UTC"
        }})</span
      >
    </div>
    <div class="flex-1 font-size-14">
      <h3 class="font-semi-bold font-link">Valor final</h3>
      <span
        >{{ metric()?.finalValue }} ({{
          metric()?.finalDate | date : "dd/MM/yyyy" : "UTC"
        }})</span
      >
    </div>
  </div>

  <div class="divisor my-15"></div>

  <div class="column">
    <div class="row">
      <span class="font-link font-semi-bold flex-1">Data</span>
      <span class="font-link font-semi-bold flex-1">Valor</span>
    </div>
    @for (item of entries(); track $index) {
    <div class="row">
      <span class="flex-1">{{
        item.createdDate | date : "dd/MM/yyyy" : "UTC"
      }}</span>
      <span class="flex-1">{{ item.value }}</span>
    </div>
    } @empty {
    <div class="row">
      <span class="flex-1">-</span>
      <span class="flex-1">-</span>
    </div>
    }
  </div>
</div>
