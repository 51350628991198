import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  Output,
  signal
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ICheckin,
  IMetric
} from '../../../core/interfaces/action-plans.interface';
import { InputComponent } from '../../forms/input/input.component';
import { HistoricalMetricsComponent } from '../historical-metrics/historical-metrics.component';
import { GoalsService } from '../../../core/services/goals.service';
import { AllowedDirective } from '../../../core/directives/allowed.directive';

@Component({
  selector: 'app-metrics-section',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    HistoricalMetricsComponent,
    FormsModule,
    AllowedDirective
  ],
  templateUrl: './metrics-section.component.html',
  styleUrl: './metrics-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricsSectionComponent {
  @Input({ required: true }) goalId: string = '';
  metrics = input<IMetric[]>([]);

  historicMode = signal<string | null>(null);
  checkinMode = signal<string[]>([]);

  @Output() onChange = new EventEmitter<IMetric[]>();

  constructor(private goalsService: GoalsService) {}

  handleHistoricMode = (id: string) => {
    setTimeout(() => {
      this.historicMode.set(id);
    }, 100);
  };

  addCheckin = (id: string) => {
    this.checkinMode.update((prev) => [...prev, id]);
  };

  removeCheckin = (id: string) => {
    this.checkinMode.update((prev) => prev.filter((item) => item !== id));
  };

  onSubmit(metric: IMetric): void {
    let newValue = metric.newValue.trim();

    this.goalsService
      .updateMetricValue(this.goalId, metric.id!, newValue)
      .subscribe((response) => {
        let checkin: ICheckin = {
          createdDate: new Date().toISOString(),
          value: newValue
        };

        metric.entries.push(checkin);
        metric.currentValue = newValue;
        metric.lastEntryDate = new Date().toISOString();
        metric.newValue = '';

        this.checkinMode.update((m) => m.filter((item) => item !== metric.id));
        this.onChange.emit(this.metrics());
      });
  }
}
