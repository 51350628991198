import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal
} from '@angular/core';
import { MetricsSectionComponent } from '../../../../shared/components/metrics-section/metrics-section.component';
import { MembersSectionComponent } from '../../../../shared/components/members-section/members-section.component';
import { TaskActionPlansComponent } from '../../../../shared/components/task-action-plans/task-action-plans.component';
import { DocumentsSectionComponent } from '../../../../shared/components/documents-section/documents-section.component';
import { CommentsComponent } from '../../../../shared/components/comments/comments.component';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef
} from '@angular/material/dialog';
import {
  IGoal,
  IMember
} from '../../../../core/interfaces/action-plans.interface';
import { GoalsService } from '../../../../core/services/goals.service';
import { Router, RouterModule } from '@angular/router';
import { AllowedDirective } from '../../../../core/directives/allowed.directive';

@Component({
  selector: 'app-action-plans-details',
  standalone: true,
  imports: [
    CommonModule,
    MetricsSectionComponent,
    MembersSectionComponent,
    TaskActionPlansComponent,
    DocumentsSectionComponent,
    CommentsComponent,
    MatDialogClose,
    MatDialogActions,
    RouterModule,
    AllowedDirective
  ],
  templateUrl: './action-plans-details.component.html',
  styleUrl: './action-plans-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionPlansDetailsComponent implements OnInit {
  goal!: IGoal;
  availableUsers = signal<IMember[]>([]);

  constructor(
    private dialogRef: MatDialogRef<ActionPlansDetailsComponent>,
    private goalsService: GoalsService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: IGoal
  ) {
    this.goal = data;
  }

  ngOnInit(): void {
    this.setAvailableUsers();
  }

  editGoal(): void {
    this.dialogRef.close(null);
    this.router.navigate(['/plataforma/metas/formulario'], {
      queryParams: { meta: this.goal.id }
    });
  }

  setAvailableUsers(): void {
    this.availableUsers.set(
      this.goalsService.availableMembers().map((member) => {
        return {
          ...member,
          isMember: this.data.members?.some((m) => m.id === member.id),
          isResponsible: this.data.responsible?.id === member.id
        };
      })
    );
  }

  changeData(dataValue: any, key: keyof IGoal): void {
    if (dataValue instanceof Array) {
      (this.goal[key] as any) = [...dataValue];
    } else {
      (this.goal[key] as any) = dataValue;
    }
  }

  closeModal(): void {
    this.dialogRef.close(this.goal);
  }
}
