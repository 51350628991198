<section class="dialog-ctn">
  <button type="button" class="btn-icon" (click)="closeModal()">
    <img width="16px" src="/assets/icons/close-dialog.svg" alt="fechar" />
  </button>
  <div class="column pt-50 px-30">
    <div class="row align-center justify-space-between column-gap-20 header">
      <div class="goal-title">
        <h1 class="font-size-18">
          {{ goal.description }}
        </h1>
      </div>
      <div>
        <button
          *allowed="['goals-edit']"
          type="button"
          class="btn-secondary px-20"
          (click)="editGoal()"
        >
          Editar
        </button>
      </div>
    </div>
    <div class="divisor mt-20 mb-15"></div>
  </div>
  <section class="form pb-30 px-30">
    <app-metrics-section
      [goalId]="goal.id!"
      [metrics]="goal.metrics"
      (onChange)="changeData($event, 'metrics')"
    ></app-metrics-section>
    <div class="divisor my-35"></div>
    <app-members-section
      mode="details"
      [goalId]="goal.id!"
      [responsible]="goal.responsible"
      [users]="availableUsers()"
      (onChange)="changeData($event, 'members'); setAvailableUsers()"
    ></app-members-section>
    <div class="divisor my-35"></div>
    <app-task-action-plans
      mode="details"
      [goalId]="goal.id!"
      [tasks]="goal.actionPlans"
      [users]="availableUsers()"
      (onChange)="changeData($event, 'actionPlans')"
    ></app-task-action-plans>
    <div class="divisor my-35"></div>
    <app-documents-section
      [goalId]="goal.id!"
      [documents]="goal.uploads"
      (onChange)="changeData($event, 'uploads')"
    ></app-documents-section>
    <div class="divisor my-35"></div>
    <app-comments
      [goalId]="goal.id!"
      [comments]="goal.activities"
      [users]="availableUsers()"
      (onChange)="changeData($event, 'activities')"
    ></app-comments>
  </section>
</section>
