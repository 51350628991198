import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  input
} from '@angular/core';
import { IGoalDocument } from '../../../core/interfaces/action-plans.interface';
import { AlertService } from '../../../core/services/alert.service';
import { AllowedDirective } from '../../../core/directives/allowed.directive';
import { GoalsService } from '../../../core/services/goals.service';
import { MatDialog } from '@angular/material/dialog';
import { ICustomDialogConfig } from '../../../core/interfaces/generic.interface';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { RemoveDateFilePipe } from '../../../core/pipes/remove-date-file.pipe';

@Component({
  selector: 'app-documents-section',
  standalone: true,
  imports: [CommonModule, AllowedDirective, RemoveDateFilePipe],
  templateUrl: './documents-section.component.html',
  styleUrl: './documents-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsSectionComponent {
  @Input() goalId: string = '';
  documents = input<IGoalDocument[]>([]);

  @Output() onChange = new EventEmitter<IGoalDocument[]>();

  file: File | null = null;

  constructor(
    private alertService: AlertService,
    private goalsService: GoalsService,
    private dialog: MatDialog
  ) {}

  openDocument(data: IGoalDocument): void {
    this.goalsService
      .downloadGoalFile(this.goalId, data.id!)
      .subscribe((response) => {
        if (response) {
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = url;
          a.download = data.name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      });
  }

  onSelect(event: any) {
    if (event.target.files.length > 1) {
      this.alertService.show(
        'error',
        'Por favor, selecione apenas um arquivo!'
      );
      return;
    }

    const file = event.target.files[0];

    if (file.size > 10 * 1024 * 1024) {
      // 10 MB
      this.alertService.show('error', 'O arquivo deve ter no máximo 10MB!');
      return;
    }

    this.file = file;
    this.submitFile();

    event.target.value = null;
  }

  submitFile(): void {
    this.goalsService
      .uploadGoalFile(this.goalId, this.file!)
      .subscribe((response) => {
        if (response) {
          this.onChange.emit([...this.documents(), response]);
          this.file = null;
        }
      });
  }

  removeDocument(document: IGoalDocument): void {
    let dialogRef = this.dialog.open<
      CustomDialogComponent,
      ICustomDialogConfig
    >(CustomDialogComponent, {
      data: {
        title: 'Excluir documento?',
        confirmText: 'Excluir',
        message: 'A exclusão de um documento é permanente.'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.goalsService
          .deleteGoalFile(this.goalId, document.id!)
          .subscribe(() => {
            this.onChange.emit(
              this.documents().filter((item) => item !== document)
            );
          });
      }
    });
  }
}
