<div class="d-flex direction-column row-gap-20">
  <h2 class="font-semi-bold">Documentos</h2>
  @for (item of documents(); track $index) {
  <div class="d-flex column-gap-15 align-center">
    <img src="/assets/icons/document.svg" alt="documento" />
    <div>
      <div class="font-size-14 d-flex column-gap-20 align-center mb-2">
        <span>{{ item.name | removeDateFile }}</span>
      </div>
      <div class="font-size-14 d-flex column-gap-20 align-center">
        <span>Adicionado em {{ item.createdDate | date : "dd/MM/yyyy" }}</span
        ><a class="font-semi-bold" (click)="openDocument(item)">Abrir</a
        ><a
          *allowed="['goals-edit']"
          class="font-semi-bold"
          (click)="removeDocument(item)"
          >Excluir</a
        >
      </div>
    </div>
  </div>
  } @empty {
  <span class="font-size-14 font-regular row">Nenhum documento adicionado</span>
  }
  <div *allowed="['goals-edit']">
    <button
      type="button"
      class="btn-secondary px-25"
      (click)="fileInput.click()"
    >
      Anexar arquivo
    </button>
    <input
      type="file"
      #fileInput
      (change)="onSelect($event)"
      style="display: none"
    />
  </div>
</div>
