import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  input,
  OnDestroy,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  IComment,
  IMember
} from '../../../core/interfaces/action-plans.interface';
import { ICustomDialogConfig } from '../../../core/interfaces/generic.interface';
import { TextareaComponent } from '../../forms/textarea/textarea.component';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { MemberInfoComponent } from '../member-info/member-info.component';
import { AuthService } from '../../../core/services/security/auth.service';
import { GoalsService } from '../../../core/services/goals.service';
import { Editor, NgxEditorModule } from 'ngx-editor';
import { AllowedDirective } from '../../../core/directives/allowed.directive';

@Component({
  selector: 'app-comments',
  standalone: true,
  imports: [
    CommonModule,
    MemberInfoComponent,
    TextareaComponent,
    FormsModule,
    NgxEditorModule,
    AllowedDirective
  ],
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsComponent implements OnInit, OnDestroy {
  @Input() goalId: string = '';
  comments = input<IComment[]>([]);
  users = input<IMember[]>([]);

  userActive = signal<IMember | null>(null);

  commentValue = '';
  rawDescriptionValue = '';
  commentEdit = signal<IComment | null>(null);

  commentsOrdered = computed(() => {
    return this.comments().sort((a, b) => {
      const dateA = a.createdDate ? new Date(a.createdDate) : new Date();
      const dateB = b.createdDate ? new Date(b.createdDate) : new Date();
      return dateB.getTime() - dateA.getTime(); // Ordena de forma decrescente
    });
  });

  @Output() onChange = new EventEmitter<IComment[]>();

  editorCreate!: Editor;
  editorUpdate!: Editor;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private goalsService: GoalsService
  ) {}

  ngOnInit(): void {
    this.editorCreate = new Editor();
    this.editorUpdate = new Editor();

    this.userActive.set(
      this.users().find((u) => u.id === this.authService.userActive()?.id)! ??
        this.authService.userActive()
    );
  }

  ngOnDestroy(): void {
    this.editorCreate.destroy();
    this.editorUpdate.destroy();
  }

  sendMessage(): void {
    let comment: IComment = {
      description: this.commentValue,
      author: this.userActive()!,
      authorId: this.userActive()!.id
    };

    this.goalsService
      .createComment(this.goalId, comment)
      .subscribe((response) => {
        this.onChange.emit([...response]);
        this.commentValue = '';
      });
  }

  editComment(comment: IComment) {
    this.commentEdit.set(comment);
    this.rawDescriptionValue = comment.description;
  }

  deleteComment(comment: IComment) {
    let dialogRef = this.dialog.open<
      CustomDialogComponent,
      ICustomDialogConfig
    >(CustomDialogComponent, {
      data: {
        title: 'Excluir comentário?',
        confirmText: 'Excluir',
        message: 'A exclusão de um comentário é permanente.'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.goalsService
          .deleteComment(this.goalId, comment.id!)
          .subscribe(() => {
            this.onChange.emit(
              this.comments().filter((c) => c.id !== comment.id)
            );
          });
      }
    });
  }

  saveComment(comment: IComment) {
    if (
      comment.description === this.rawDescriptionValue ||
      !comment.description?.trim()
    ) {
      comment.description = this.rawDescriptionValue;
      this.commentEdit.set(null);
      return;
    }

    this.goalsService.updateComment(this.goalId, comment).subscribe(() => {
      this.onChange.emit(this.comments());
      this.commentEdit.set(null);
    });
  }

  handleAnswer(comment: IComment) {
    this.commentValue = `<strong>@${comment.author.name.replace(
      /\s/g,
      ''
    )}</strong>&nbsp;`;
  }

  onEditorChange(content: string) {
    content = content.replace(/<\/?[^>]+(>|$)/g, '');

    if (!content.trim()) {
      this.commentValue = '';
    }
  }
}
