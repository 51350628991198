import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  HostListener,
  input,
  Output,
  ViewChild
} from '@angular/core';
import { IMetric } from '../../../core/interfaces/action-plans.interface';

@Component({
  selector: 'app-historical-metrics',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './historical-metrics.component.html',
  styleUrl: './historical-metrics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoricalMetricsComponent {
  metric = input<IMetric | null>(null);

  entries = computed(() => {
    return this.metric()?.entries.sort((a, b) => {
      return (
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );
    });
  });

  @Output() onClose = new EventEmitter();

  @ViewChild('targetMetrics') targetDivRef!: ElementRef;

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    if (
      this.targetDivRef &&
      !this.targetDivRef.nativeElement.contains(event.target)
    ) {
      this.onClose.emit();
    }
  }
}
