<section class="pb-40">
  <div>
    <h2 class="font-semi-bold pb-20">Comentários</h2>
    <div *allowed="['goals-edit']" class="row align-center pb-20">
      <app-member-info class="mr-10" [member]="userActive()"></app-member-info>
      <div class="flex-1">
        <!-- <ngx-editor-menu [editor]="editorCreate"> </ngx-editor-menu> -->
        <ngx-editor
          [editor]="editorCreate"
          [(ngModel)]="commentValue"
          [disabled]="false"
          placeholder="Digite um comentário"
          (ngModelChange)="onEditorChange($event)"
        ></ngx-editor>
      </div>
      <button
        type="button"
        class="btn-icon send-btn position-relative"
        [disabled]="!commentValue || !commentValue.trim()"
        (click)="sendMessage()"
      >
        <img
          class="send-default"
          width="32px"
          src="/assets/icons/send-hover.svg"
          alt=""
        />
        <img
          class="send-hover"
          width="32px"
          src="/assets/icons/send-primary.png"
          alt=""
        />
      </button>
    </div>
  </div>

  <div class="column">
    @for (item of commentsOrdered(); track $index) {
    <div class="row column-gap-10 py-10">
      <div>
        <app-member-info class="mr-10" [member]="item.author"></app-member-info>
      </div>
      <div class="column row-gap-8 flex-1">
        <div class="row column-gap-10 font-size-14">
          <span>{{ item.author.name }}</span>
          <span>{{ item.createdDate | date : "dd/MM/yyyy" }}</span>
        </div>
        @if (commentEdit()?.id === item.id) {
        <ngx-editor
          [editor]="editorUpdate"
          [(ngModel)]="item.description"
          [disabled]="false"
          placeholder="Digite um comentário"
        ></ngx-editor>
        } @else {
        <div class="box-comment">
          <span [innerHTML]="item.description"> </span>
        </div>
        }
        <div class="row font-size-14 font-semi-bold column-gap-10">
          @if (item.authorId === userActive()?.id) { @if (!commentEdit()) {
          <ng-container *allowed="['goals-edit']">
            <a (click)="editComment(item)">Editar</a>
            <a (click)="deleteComment(item)">Excluir</a>
          </ng-container>
          } @else if(commentEdit()?.id === item.id) {
          <a (click)="saveComment(item)">Salvar</a>
          <a
            (click)="
              commentEdit.set(null); item.description = rawDescriptionValue
            "
            >Cancelar</a
          >
          } } @else {
          <a *allowed="['goals-edit']" (click)="handleAnswer(item)"
            >Responder</a
          >
          }
        </div>
      </div>
    </div>
    }
  </div>
</section>
