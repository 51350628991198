<section class="column row-gap-30">
  @for (item of metrics(); track $index) {
  <div class="column row-gap-15">
    <h2 class="font-semi-bold">{{ item.description }}</h2>

    <div class="row align-center">
      <div class="flex-1 font-size-14">
        <h3 class="font-semi-bold">Valor inicial</h3>
        <span
          >{{ item.initialValue }} ({{
            item.initialDate | date : "dd/MM/yyyy" : "UTC"
          }})</span
        >
      </div>
      <div class="flex-1 font-size-14">
        <h3 class="font-semi-bold">Valor final</h3>
        <span
          >{{ item.finalValue }} ({{
            item.finalDate | date : "dd/MM/yyyy" : "UTC"
          }})</span
        >
      </div>
      <div class="flex-1 font-size-14">
        <h3 class="font-semi-bold">Valor atual</h3>
        @if (item.currentValue) {
        <span class="font-semi-bold current"
          >{{ item.currentValue }} ({{
            item.lastEntryDate | date : "dd/MM/yyyy" : "UTC"
          }})</span
        >
        } @else {
        <span class="font-semi-bold current">-</span>
        }
      </div>
      <div class="flex-1">
        @if (!checkinMode().includes(item.id!)) {
        <button
          *allowed="['goals-edit']"
          type="button"
          class="btn-primary px-25"
          (click)="addCheckin(item.id!)"
        >
          Fazer check-in
        </button>
        }
      </div>
    </div>

    @if (checkinMode().includes(item.id!)) {
    <div class="row align-flex-end column-gap-30">
      <div class="flex-1 font-size-14">
        <h3 class="font-semi-bold">Novo valor</h3>
        <app-input [openFocus]="true" [(ngModel)]="item.newValue"></app-input>
      </div>
      <div class="row flex-1 column-gap-20">
        <button
          type="button"
          class="btn-primary px-40"
          [disabled]="!item.newValue || !item.newValue.trim()"
          (click)="onSubmit(item)"
        >
          Atualizar
        </button>
        <button
          type="button"
          class="btn-secondary px-35"
          (click)="removeCheckin(item.id!)"
        >
          Cancelar
        </button>
      </div>
    </div>
    }

    <div class="position-relative">
      <a
        class="font-size-14 font-semi-bold"
        (click)="handleHistoricMode(item.id!)"
        >Ver histórico</a
      >
      @if (historicMode() === item.id) {
      <app-historical-metrics
        [metric]="item"
        (onClose)="historicMode.set(null)"
      ></app-historical-metrics>
      }
    </div>
  </div>
  } @empty {
  <p class="font-size-14">Nenhuma métrica cadastrada</p>
  }
</section>
