import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDateFile',
  standalone: true
})
export class RemoveDateFilePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value === 'string') {
      const index = value.indexOf('_');
      if (index !== -1) {
        return value.substring(index + 1);
      }
    }
    return value;
  }
}
